<template>
  <div class="container">
    <div class="wrap">
      <h1 class="text h1">404</h1>
      <el-button @click.native="pushRoute">返回首页</el-button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    pushRoute() {
      this.$router.replace('/home');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables';
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    $--color-primary 0,
    $--color-primary 30%,
    pink 100%
  );
  .wrap {
    text-align: center;
  }
}

.text {
  user-select: none;
  background: linear-gradient(
    to left,
    $--color-primary 0,
    $--color-primary 50%,
    #fff 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: -2px -2px 0px rgba(#aaa, 0.5), 0 2px 0px rgba(#fff, 0.5);

  &.h1 {
    margin: 0;
    font-size: 200px;
    font-weight: bolder;
  }
}
</style>
